export const getNextIndex = (currentIndex: number, max: number): number => {
  let nextIndex = currentIndex + 1
  if (nextIndex === max) {
    nextIndex = 0
  }

  return nextIndex
}

export const getPrevIndex = (currentIndex: number, max: number): number => {
  let prevIndex = currentIndex - 1
  if (prevIndex === -1) {
    prevIndex = max - 1
  }

  return prevIndex
}

export const getWidthFromIndex = (
  currentIndex: number,
  max: number
): string => {
  return `${Math.min(100, ((currentIndex + 1) * 100) / max + 1)}%`
}

export const sortDesc = <T extends { orderByDesc: number }>(list: T[]): T[] => {
  return [...list].sort((a, b) => b.orderByDesc - a.orderByDesc)
}
