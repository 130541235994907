import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'
import LiveEvent from 'src/sections/ChatterbugLive/subcomponents/LiveEvent/LiveEvent'
import { CBLiveEventData } from 'src/types'

export type Props = {
  events: CBLiveEventData[]
}

const LiveEvents: React.FC<Props> = ({ events }) => {
  return (
    <Grid
      as="ul"
      sx={{
        listStyle: 'none',
        padding: 0,
      }}
      gridGap="80px"
      columns={1}
    >
      {events.map((event, index) => {
        return (
          <Box as="li" key={`cb-event-${index}`}>
            <LiveEvent index={index} event={event} />
          </Box>
        )
      })}
    </Grid>
  )
}

export default LiveEvents
