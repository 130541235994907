import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Flex } from '@chatterbug/aaron'
import Tab from 'src/components/Tab/Tab'
import FadeIn from 'src/components/FadeIn/FadeIn'
import { TopFeatureSlide } from './subcomponents/TopFeaturesSlide/TopFeatureSlide'

export type FeatureItem = {
  name: string
  image: string
  heading: string
  description: string
  tabTitle?: string // or `heading`
}

export type Props = {
  items: FeatureItem[]
}

const TopFeatures: React.FC<Props> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <>
      <Helmet>
        {items.map((item, index) => (
          <link key={index} rel="preload" href={item.image} as="image" />
        ))}
      </Helmet>
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        display={{
          _: 'none',
          laptop: 'flex',
        }}
        mt={{
          _: 56,
          laptop: 24,
        }}
      >
        {items.map((item, index) => (
          <Tab
            key={`tab-${index}`}
            label={item.tabTitle || item.heading}
            name={item.name}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
            margin="24px 24px 48px 24px"
          />
        ))}
      </Flex>

      <Box
        as="ul"
        mt={{ _: 56, laptop: 0 }}
        sx={{ listStyle: 'none', padding: 0 }}
      >
        {items.map((item, index) => {
          const isCurrent = currentIndex === index
          return (
            <FadeIn
              as="li"
              key={`slide-${index}-${currentIndex}`}
              display={{ _: 'block', laptop: isCurrent ? 'block' : 'none' }}
              mt={{ _: index > 0 ? 88 : 0, laptop: 0 }}
            >
              <TopFeatureSlide index={index} {...item} />
            </FadeIn>
          )
        })}
      </Box>
    </>
  )
}

export default TopFeatures
