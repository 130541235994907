import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Flex, Box } from '@chatterbug/aaron'
import Flag from 'src/components/Flag/Flag'
import { LanguageCode } from 'src/types'

export type Props = {
  l2Code: LanguageCode
}

const LiveBadge: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()

  return (
    <Flex
      display="inline-flex"
      as="section"
      bg="yellow50"
      boxShadow="overlay"
      borderRadius="4px"
      py="1x"
      px="14px"
      alignItems="center"
    >
      <Flag code={l2Code} size="mini" />
      <Box ml="1x" variant="text.buttonTitle">
        {t('cb_live.badge')}
      </Box>
    </Flex>
  )
}

export default LiveBadge
