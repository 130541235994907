import React, { FormEventHandler } from 'react'
import { Box, Flex } from '@chatterbug/aaron'

export type Props = {
  name: string
  label: string
  placeholder: string
  hasVisibleLabel?: boolean
  type: string
  required?: boolean
  value?: string
  onChange?: FormEventHandler
  hasError?: boolean
}

export type InputErrorProps = {
  text: string
}

export const InputError: React.FC<InputErrorProps> = ({ text }) => {
  return (
    <Box
      as="p"
      ml="3x"
      pt="05x"
      overflow="hidden"
      lineHeight="1.5"
      color="red44"
      fontSize="xxs"
      display="block"
    >
      {text}
    </Box>
  )
}

const HEIGHT = 14

const Input: React.FC<Props> = ({
  name,
  hasError,
  hasVisibleLabel = false,
  placeholder,
  label,
  type,
  required = false,
  value,
  onChange,
}) => {
  return (
    <Box as="span" position="relative" display="inline-block" bg="white100">
      <Flex
        as="label"
        variant={hasVisibleLabel ? '' : 'a11y.hidden'}
        htmlFor={name}
        alignItems="center"
        sx={{ position: 'absolute', right: '3x', top: `-${HEIGHT / 2}px` }}
        px="1x"
        bg="white100"
        height={HEIGHT}
        color={hasError ? 'red44' : 'gray46'}
        fontSize="xxs"
      >
        {label + `${required ? ' *' : ''}`}
      </Flex>
      <Box
        id={name}
        as="input"
        color={hasError ? 'red44' : 'black0'}
        aria-invalid={hasError ? 'true' : 'false'}
        autoCorrect="off"
        spellCheck="false"
        autoCapitalize="none"
        variant="text.paragraph"
        name={name}
        placeholder={placeholder}
        type={type}
        required={required}
        py="1x"
        px="3x"
        width="100%"
        borderStyle="solid"
        borderWidth="small"
        borderColor={hasError ? 'red44' : 'gray80'}
        borderRadius="lg"
        value={value}
        onChange={onChange}
        sx={{
          '::placeholder': {
            color: 'gray46',
          },
        }}
      />
    </Box>
  )
}

export default Input
