import React from 'react'
import { Box, Grid, Image } from '@chatterbug/aaron'

export type Props = {
  index: number
  name: string
  image: string
  heading: string
  description: string
}

const getLabelId = (name: string) => `chatterbug-slide-${name}`

export const TopFeatureSlide: React.FC<Props> = ({
  index,
  name,
  image,
  heading,
  description,
}) => {
  const isEven = index % 2 === 0

  return (
    <Grid
      as="article"
      className="cb-feature-slide"
      columns={{ _: '1fr', tablet: '1fr 1fr' }}
      gridGap={{ _: '2x', laptop: '5rem' }}
      alignItems="center"
      maxWidth={{ _: '100%', mobile: `${(11 * 100) / 12}%`, tablet: '100%' }}
      mx="auto"
      aria-labelledby={getLabelId(name)}
    >
      <Box order={{ _: 1, tablet: isEven ? 1 : 3, laptop: 1 }}>
        <Image src={image} alt={heading} width="100%" loading="lazy" />
      </Box>
      <Box
        order={{ _: 2, tablet: isEven ? 2 : 1, laptop: 2 }}
        pl={{ laptop: '2x' }}
      >
        <Box
          variant="text.h3"
          sx={{
            fontWeight: 'semiBold',
            fontSize: 28,
            lineHeight: 1.2,
            marginBottom: 24,
          }}
          id={getLabelId(name)}
        >
          {heading}
        </Box>
        <Box
          variant="text.paragraph"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Box>
    </Grid>
  )
}
