import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import LiveBadge from 'src/sections/ChatterbugLive/subcomponents/LiveBadge/LiveBadge'
import { getLocalizedText } from 'src/lib/localization'
import { useLocale } from 'src/lib/I18nContext'
import { CBLivePageData, LanguageCode } from 'src/types'

export type Props = {
  l2Code: LanguageCode
  pageData: CBLivePageData
}

const LiveHeroContent: React.FC<Props> = ({ l2Code, pageData }) => {
  const locale = useLocale()

  return (
    <Flex
      flexDirection="column"
      alignItems={{
        _: 'center',
        tablet: 'flex-start',
      }}
      textAlign={{
        _: 'center',
        tablet: 'left',
      }}
    >
      <Flex
        alignItems="center"
        minHeight={285}
        mb={{
          _: 160,
          tablet: 140,
        }}
        width={{
          _: `${(10 * 100) / 12}%`,
          tablet: '100%',
        }}
      >
        <Box width="100%">
          <LiveBadge l2Code={l2Code} />
          <Box
            as="h1"
            mt="2x"
            variant="text.h2"
            color="white100"
            fontSize={42}
            dangerouslySetInnerHTML={{
              __html: getLocalizedText(pageData.localizedMainHeader, locale),
            }}
          />
          <Box
            as="p"
            mt="1x"
            variant="text.paragraph"
            color="white100"
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: getLocalizedText(pageData.localizedSubHeader, locale),
            }}
          />
        </Box>
      </Flex>
      <Box
        as="p"
        variant="text.paragraph"
        fontSize={20}
        dangerouslySetInnerHTML={{
          __html: getLocalizedText(pageData.localizedDescription, locale),
        }}
      />
    </Flex>
  )
}

export default LiveHeroContent
