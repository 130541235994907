import React from 'react'
import { Box } from '@chatterbug/aaron'
import {
  CBLiveEventData,
  CBLivePageData,
  LanguageCode,
  NavigationItemName,
} from 'src/types'
import LineSeparator from 'src/components/LineSeparator/LineSeparator'
import WhyChatterbug from 'src/sections/WhyChatterbug/WhyChatterbug'
import PageContainer from 'src/components/PageContainer/PageContainer'

import LiveHero from './subcomponents/LiveHero/LiveHero'
import LiveEvents from './subcomponents/LiveEvents/LiveEvents'
import LiveFooter from './subcomponents/LiveFooter/LiveFooter'

export type Props = {
  l2Code: LanguageCode
  canonicalPath: string
  events: CBLiveEventData[]
  pageData: CBLivePageData
}

const ChatterbugLive: React.FC<Props> = ({
  l2Code,
  canonicalPath,
  events,
  pageData,
}) => {
  return (
    <Box>
      <LiveHero
        l2Code={l2Code}
        canonicalPath={canonicalPath}
        pageData={pageData}
      />
      {events.length > 0 && (
        <Box as="section" mt={88}>
          <PageContainer>
            <LineSeparator />
            <Box my={80}>
              <LiveEvents events={events} />
            </Box>
            <LineSeparator />
          </PageContainer>
        </Box>
      )}
      <Box as="section" id={NavigationItemName.whyChatterbug} mt={56}>
        <WhyChatterbug />
      </Box>
      <Box as="section" mt={88}>
        <LiveFooter l2Code={l2Code} />
      </Box>
    </Box>
  )
}

export default ChatterbugLive
