import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { useTranslate } from 'react-polyglot'
import Head from 'src/components/Head/Head'
import Layout from 'src/components/Layout/Layout'
import ChatterbugLive from 'src/sections/ChatterbugLive/ChatterbugLive'
import { sortDesc } from 'src/lib/utils'
import { getLocalizedText } from 'src/lib/localization'
import { useLocale } from 'src/lib/I18nContext'
import {
  CBLiveEventsJSON,
  CBLivePagesJSON,
  CBLivePageData,
  LanguageCode,
  PageContext,
  SiteLocale,
} from 'src/types'

type DataProps = {
  allCbLiveEventsJson: CBLiveEventsJSON
  allCbLivePagesJson: CBLivePagesJSON
}

const removeMarkup = (text: string): string =>
  text.replace('<strong>', '').replace('</strong>', '')

const ChatterbugLiveLanguagePage: React.FC<PageProps<
  DataProps,
  PageContext
>> = ({ pageContext, data }) => {
  const t = useTranslate()
  const locale = useLocale()
  const l2Code = pageContext.l2Code || LanguageCode.de
  const events = sortDesc(data.allCbLiveEventsJson?.nodes || [])
  const FALLBACK_PAGE_DATA: CBLivePageData = {
    l2Code,
    socialImage: '',
    localizedMainHeader: [{ locale: SiteLocale.en, text: 'Online Class!' }],
    localizedSubHeader: [
      { locale: SiteLocale.en, text: 'Free. Interactive. Live!' },
    ],
    localizedDescription: [{ locale: SiteLocale.en, text: '' }],
  }
  const pageData = {
    ...FALLBACK_PAGE_DATA,
    ...data.allCbLivePagesJson?.nodes?.[0],
  }

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        title={`${t('cb_live.page_title')} ${removeMarkup(
          getLocalizedText(pageData.localizedMainHeader, locale)
        )}`}
        description={removeMarkup(
          getLocalizedText(pageData.localizedDescription, locale)
        )}
        socialImage={pageData.socialImage}
        pageContext={pageContext}
      />
      <ChatterbugLive
        l2Code={l2Code}
        canonicalPath={pageContext.canonicalPath}
        events={events}
        pageData={pageData}
      />
    </Layout>
  )
}

export default ChatterbugLiveLanguagePage

export const query = graphql`
  query CBEvents($l2Code: String) {
    allCbLiveEventsJson(
      filter: { l2Code: { eq: $l2Code }, hidden: { ne: true } }
    ) {
      nodes {
        name
        image
        l2Code
        localizedTitle {
          locale
          text
        }
        localizedWhen {
          locale
          text
        }
        localizedDescription {
          locale
          text
        }
        orderByDesc
        hidden
      }
    }
    allCbLivePagesJson(filter: { l2Code: { eq: $l2Code } }) {
      nodes {
        l2Code
        socialImage
        localizedMainHeader {
          locale
          text
        }
        localizedSubHeader {
          locale
          text
        }
        localizedDescription {
          locale
          text
        }
      }
    }
  }
`
