import React from 'react'
import { Flex, Box } from '@chatterbug/aaron'
import { CBLiveEventData } from 'src/types'
import { getLocalizedText } from 'src/lib/localization'
import { useLocale } from 'src/lib/I18nContext'

export type Props = {
  event: CBLiveEventData
}

const LiveEventContent: React.FC<Props> = ({ event }) => {
  const locale = useLocale()

  return (
    <Flex flexDirection="column">
      <Box as="p" variant="text.buttonTitle" color="gray46">
        {getLocalizedText(event.localizedWhen, locale)}
      </Box>
      <Box as="h2" mt="1x" variant="text.h3" color="blue42">
        {getLocalizedText(event.localizedTitle, locale)}
      </Box>
      <Box
        as="p"
        mt="3x"
        variant="text.paragraph"
        dangerouslySetInnerHTML={{
          __html: getLocalizedText(event.localizedDescription, locale),
        }}
        sx={{
          whiteSpace: 'pre-wrap',
        }}
      />
    </Flex>
  )
}

export default LiveEventContent
