import React, { useEffect, useState } from 'react'
import { Box } from '@chatterbug/aaron'

const FROM_OPACITY = 0
const TO_OPACITY = 1

export type Props = {
  sx?: Record<string, unknown>
  [key: string]: unknown
  disabled?: boolean
}

const FadeIn: React.FC<Props> = ({ children, sx, disabled, ...props }) => {
  const [opacity, setOpacity] = useState(
    typeof window === 'undefined' ? TO_OPACITY : FROM_OPACITY
  )

  useEffect(() => {
    if (opacity !== TO_OPACITY) {
      setOpacity(TO_OPACITY)
    }
  }, [opacity, setOpacity])

  return (
    <Box
      {...props}
      sx={{ opacity, transition: disabled ? '' : 'opacity 0.5s ease', ...sx }}
    >
      {children}
    </Box>
  )
}

export default FadeIn
