import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'
import { LanguageCode } from 'src/types'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import ActionCard, { BUTTON_WIDTH } from 'src/components/ActionCard/ActionCard'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { useLocale } from 'src/lib/I18nContext'
import { CB_LINKS } from 'src/lib/app'

import background from './assets/background.svg'

export type Props = {
  l2Code: LanguageCode
}

const LiveFooter: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <FullWidthBackground image={background} width={1440} height={300}>
      <PageContainer>
        <Box py={120}>
          <ActionCard
            message={t('cb_live_footer.message')}
            customButton={
              <Button
                target="_blank"
                rel="noopener"
                href={CB_LINKS.social.youtube}
                variant={BUTTON_VARIANTS.primary}
                label={t('cb_live_footer.cta')}
                width={BUTTON_WIDTH}
                height="44px"
                className="cb-live-button-youtube"
                data-l1={locale}
                data-l2={l2Code}
              />
            }
          />
        </Box>
      </PageContainer>
    </FullWidthBackground>
  )
}

export default LiveFooter
