const MAILCHIMP_SIGNUP_FORM_URL =
  'https://chatterbug.us19.list-manage.com/subscribe/post'
const MAILCHIMP_ACCOUNT_ID = '8f37017a15eb853cc5c706d2f'

const CB_LIVE_GERMAN = {
  l2Code: 'de',
  canonicalPath: '/live/german',
  mailchimpAudienceId: 'f11e7ef64f',
}

const CB_LIVE_ENGLISH = {
  l2Code: 'en',
  canonicalPath: '/live/english',
  mailchimpAudienceId: 'cfe0be44c7',
}

// used in `gatsby-node.js` to create a redirect
// and also used in components
const CB_LIVE_PAGES_MAP = {
  de: CB_LIVE_GERMAN,
  en: CB_LIVE_ENGLISH,
}

// used in `gatsby-node.js` to create pages
// and also used in components
const CB_LIVE_PAGES_LIST = [CB_LIVE_GERMAN, CB_LIVE_ENGLISH]

module.exports = {
  MAILCHIMP_SIGNUP_FORM_URL,
  MAILCHIMP_ACCOUNT_ID,
  CB_LIVE_PAGES_MAP,
  CB_LIVE_PAGES_LIST,
}
