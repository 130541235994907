import React from 'react'
import { Flex, Box } from '@chatterbug/aaron'
import Logo from 'src/components/Logo/Logo'
import Header from 'src/components/Header/Header'
import { LanguageCode, NavigationItem } from 'src/types'

export type Props = {
  l2Code: LanguageCode
  canonicalPath: string
}

const ITEMS: NavigationItem[] = []

const LiveHeader: React.FC<Props> = ({ l2Code, canonicalPath }) => {
  return (
    <Header
      l2Code={l2Code}
      canonicalPath={canonicalPath}
      backgroundType="dark"
      items={ITEMS}
      hideLogin
      customLogo={
        <Flex alignItems="center">
          <Logo iconOnly isBackgroundDark />
          <Box variant="text.h5" color="white100" ml="1x">
            {'chatterbug live'}
          </Box>
        </Flex>
      }
    />
  )
}

export default LiveHeader
