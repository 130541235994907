import React from 'react'
import { Box, Grid, Image } from '@chatterbug/aaron'
import { CBLiveEventData } from 'src/types'
import { getLocalizedText } from 'src/lib/localization'
import { useLocale } from 'src/lib/I18nContext'

import LiveEventContent from './subcomponents/LiveEventContent'

export type Props = {
  index: number
  event: CBLiveEventData
}

const MAX_TEXT_LENGTH = 450 // it means the height of the text is less than image height

const LiveEvent: React.FC<Props> = ({ index, event }) => {
  const locale = useLocale()
  const isEven = index % 2 === 0
  const sidePadding = `${(1 * 100) / 6}%` // 1 out of 6 columns
  const sidePaddingProp = isEven ? 'paddingLeft' : 'paddingRight'

  return (
    <Grid
      as="article"
      gridGap={{ _: '4x', tablet: '2x' }}
      columns={{
        _: '1fr',
        tablet: '1fr 1fr',
      }}
      gridTemplateAreas={{
        _: `'image' 'content'`,
        tablet: isEven ? `'image content'` : `'content image'`,
      }}
      alignItems={{
        _: 'flex-start',
        laptop:
          getLocalizedText(event.localizedDescription, locale).length >
          MAX_TEXT_LENGTH
            ? 'flex-start'
            : 'center',
      }}
    >
      <Box borderRadius="md" sx={{ gridArea: 'image', overflow: 'hidden' }}>
        <Image
          display="block"
          src={event.image}
          width="100%"
          alt={getLocalizedText(event.localizedTitle, locale)}
          loading="lazy"
        />
      </Box>
      <Box
        sx={{ gridArea: 'content' }}
        // responsive padding on left or right side
        {...{ [sidePaddingProp]: { tabletWide: sidePadding } }}
      >
        <LiveEventContent event={event} />
      </Box>
    </Grid>
  )
}

export default LiveEvent
