import React from 'react'
import { Box, Flex, Grid, Image } from '@chatterbug/aaron'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import PageContainer from 'src/components/PageContainer/PageContainer'
import LiveHeader from 'src/sections/ChatterbugLive/subcomponents/LiveHeader/LiveHeader'
import LiveForm from 'src/sections/ChatterbugLive/subcomponents/LiveForm/LiveForm'
import { CBLivePageData, LanguageCode } from 'src/types'

import LiveHeroContent from './subcomponents/LiveHeroContent'
import background from './assets/background.svg'
import characters from './assets/characters.svg'

export type Props = {
  l2Code: LanguageCode
  canonicalPath: string
  pageData: CBLivePageData
}

const LiveHero: React.FC<Props> = ({ l2Code, canonicalPath, pageData }) => {
  return (
    <Box as="section" position="relative" bg="gray99">
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <FullWidthBackground image={background} width={1440} height={650} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 426,
          left: 0,
          width: '100%',
        }}
      >
        <PageContainer>
          <Flex
            minHeight={132}
            flexDirection="column"
            justifyContent="center"
            alignItems={{
              _: 'center',
              tablet: 'flex-start',
            }}
          >
            <Image
              src={characters}
              width={368}
              maxWidth={{ tablet: '50%' }}
              alt=""
            />
          </Flex>
        </PageContainer>
      </Box>
      <Box position="relative">
        <PageContainer>
          <LiveHeader l2Code={l2Code} canonicalPath={canonicalPath} />
          <Grid
            columns={{
              _: '1fr',
              tablet: '1fr 1fr',
            }}
            gap={{
              _: 56,
              tablet: '2x',
            }}
            mt={{
              _: 72,
              tablet: 104,
            }}
          >
            <LiveHeroContent l2Code={l2Code} pageData={pageData} />
            <Box
              pl={{
                tabletWide: `${(1 * 100) / 6}%`,
              }}
            >
              <LiveForm l2Code={l2Code} />
            </Box>
          </Grid>
        </PageContainer>
      </Box>
    </Box>
  )
}

export default LiveHero
