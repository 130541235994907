import React from 'react'
import { Box } from '@chatterbug/aaron'
import { CSSProperties } from 'styled-components'
import { usePageThemeContext } from 'src/lib/pageThemeContext'

export type Props = {
  label: string
  name: string
  active: boolean
  onClick: (name: string) => void
} & CSSProperties

const Tab: React.FC<Props> = ({ label, name, active, onClick, ...other }) => {
  const pageTheme = usePageThemeContext()

  return (
    <Box
      as="button"
      alignItems="center"
      data-name={name}
      className="cb-tab"
      variant="text.buttonTitle"
      sx={{
        display: 'inline-flex',
        background: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'row nowrap',
        fontSize: 'xxs',
        fontWeight: 'semiBold',
        color: active ? 'black19' : 'gray60',
        letterSpacing: '0.96px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'color 0.3s ease',
        ':hover, :active, :focus': {
          textDecoration: 'none',
          color: 'black19',
        },
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        ...other,
      }}
      onClick={() => onClick(name)}
    >
      <Box
        sx={{
          display: 'inline-block',
          paddingBottom: '8px',
          transition: 'border-color 0.3s ease',
          borderBottomWidth: 2,
          borderBottomColor: active ? pageTheme.accentColor : 'transparent',
          borderBottomStyle: 'solid',
        }}
      >
        {label}
      </Box>
    </Box>
  )
}

export default Tab
