import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Grid } from '@chatterbug/aaron'
import Input from 'src/components/Input/Input'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'
import {
  CB_LIVE_PAGES_MAP,
  MAILCHIMP_ACCOUNT_ID,
  MAILCHIMP_SIGNUP_FORM_URL,
} from 'src/config/cb-live'
import { LanguageCode } from 'src/types'

type MapType = Record<
  string,
  { l2Code: string; canonicalPath: string; mailchimpAudienceId: string }
>

export type Props = {
  l2Code: LanguageCode
}

const handleChange = () => {
  /* Nothing to do here. Form submit will be handled by Mailchimp */
}

// We use Mailchimp Embedded forms (Unstyled)
// Mailchimp forms have required attributes and additional elements. Please don't remove them!

const LiveForm: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()
  const locale = useLocale()
  const mailchimpAudienceId = (CB_LIVE_PAGES_MAP as MapType)[String(l2Code)]
    ?.mailchimpAudienceId

  if (!mailchimpAudienceId) {
    return null
  }

  return (
    <Box as="section" bg="white100" boxShadow="raised" borderRadius="md" p="4x">
      <Grid
        gridGap="3x"
        as="form"
        action={`${MAILCHIMP_SIGNUP_FORM_URL}?u=${MAILCHIMP_ACCOUNT_ID}&amp;id=${mailchimpAudienceId}`} // mailchimp
        method="post" // mailchimp
        name="mc-embedded-subscribe-form" // mailchimp
        target="_blank" // mailchimp
      >
        <Box as="h2" variant="text.h3">
          {t('cb_live_form.title')}
        </Box>
        <Box as="p" variant="text.paragraph" py="1x">
          {t('cb_live_form.subtitle')}
        </Box>
        <Input
          name="EMAIL" // mailchimp
          type="email"
          required
          hasVisibleLabel
          label={`${t('cb_live_form.input_email')}`}
          placeholder={t('cb_live_form.input_email_placeholder')}
          onChange={handleChange}
        />
        <Input
          name="NAME" // mailchimp
          type="text"
          hasVisibleLabel
          label={t('cb_live_form.input_name')}
          placeholder={t('cb_live_form.input_name_placeholder')}
          onChange={handleChange}
        />
        <Input
          name="LOCATION" // mailchimp
          type="text"
          hasVisibleLabel
          label={t('cb_live_form.input_location')}
          placeholder={t('cb_live_form.input_location_placeholder')}
          onChange={handleChange}
        />
        <Input
          name="MMERGE5" // mailchimp
          type="text"
          hasVisibleLabel
          label={t('cb_live_form.input_cb_username')}
          placeholder={t('cb_live_form.input_cb_username_placeholder')}
          onChange={handleChange}
        />
        <Box pt="2x">
          {/* mailchimp start: real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <Box
            aria-hidden="true"
            sx={{
              position: 'absolute',
              left: '-5000px',
            }}
          >
            <input
              type="text"
              name={`b_${MAILCHIMP_ACCOUNT_ID}_${mailchimpAudienceId}`}
              tabIndex={-1}
              value=""
              onChange={handleChange}
            />
          </Box>
          {/* mailchimp end */}
          <Button
            href={null}
            type="submit"
            variant={BUTTON_VARIANTS.primary}
            height="44px"
            width="100%"
            label={t('cb_live_form.cta')}
            className="cb-live-form-submit"
            data-l1={locale}
            data-l2={l2Code}
          />
        </Box>
        {l2Code !== LanguageCode.en && (
          <Box as="p" variant="text.paragraph" color="gray46" fontSize="xxs">
            {t('cb_live_form.footer')}
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default LiveForm
